.App {
  text-align: center;
  font-size: 12px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-box {
  text-align: center;
  font-weight: bold;
  font-size: 12px;
}

.show {
  display: flex;
  justify-content: center; /* 이미지 중앙 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
  width: 100%; /* 부모 요소의 전체 너비 사용 */
  height: 800px; /* 높이 설정 */
  overflow: hidden;
}

.show img {
  width: 800px; /* 이미지 너비 설정 */
  max-width: 100%; /* 부모 요소 너비에 맞추기 */
  height: auto; /* 이미지 비율 유지 */
}
